import 'jquery.cookie';
const openingvideo = document.getElementById('opening-video');

if ($.cookie("access") == undefined) {
  $.cookie("access", "onece");
  console.log('kita0');
  $('header').css({ 'display': 'none' });
  $('body').css({ 'overflow': 'hidden' });
  $('html').css({ 'overflow': 'hidden' });
  openingvideo.addEventListener("ended", function () {
    $('.opening').fadeOut(300);
    $('header').fadeIn(0);
    document.getElementById('main-video').play();
    $('body').css({ 'overflow': 'auto' });
    $('html').css({ 'overflow': 'auto' });
  });

} else {
  $('.opening').css({ 'display': 'none' });
  $('header').css({ 'display': 'block' })
  document.getElementById('main-video').play();

}
